// Vendors
import tippy from 'tippy.js';
window.tippy = tippy;

import select2 from 'select2';
window.select2 = select2;

import IMask from 'imask';
window.IMask = IMask;


// Components
import { commonInit } from './classes/commonInit';
new commonInit();

import { changePrice } from './classes/changePrice';
new changePrice();

import { singleBuilding } from './classes/singleBuilding';
new singleBuilding();

import { popupActions } from './classes/popupActions';
new popupActions();
