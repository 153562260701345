import rater from 'rater-js';
import Sticky from "sticky-js";
import IMask from 'imask';
window.IMask = IMask;

export class commonInit {
  constructor() {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }
  }

  init() {
    this.setEvents();

    this.initRating();
    this.toggleInfo();
    this.initMask();

    this.activateSticky();

    this.toggleSeoText();
    this.changeAgreement();
  }

  setEvents() {
    let eyes = document.querySelectorAll('[class*=eye]');
    eyes.forEach(item=>{
      item.addEventListener('click', (e) => this.togglePass(e));
    });

    let input_password = document.querySelector('#Password');
    if (input_password) {
      input_password.addEventListener("keydown", event => {
        if (event.keyCode === 13) {
          document.querySelector('.login-form__submit button').click();
        }
      });
    }

    document.body.addEventListener('click', (e) => this.bodyClickWatch(e));

    // let agent = document.querySelector('[name="User.Agent"]');
    // agent.addEventListener('change', (e) => this.changeAgent(e));
    $('[name="User.Agent"]').on("change", (e) => this.changeAgent(e));
  }

  toggleSeoText() {
    let aSeoTextOpen = $('[data-id="aSeoTextOpen"]');
    let divSeoText = $('[data-id="divSeoText"]');

    aSeoTextOpen.on('click', function () {
      let isOpened = divSeoText.data('is-opened');
      if (isOpened) {
        divSeoText.data('is-opened', false);
        divSeoText.attr('style', 'height: 150px;');
        aSeoTextOpen.html('Показать больше');
      } else {
        divSeoText.data('is-opened', true);
        divSeoText.removeAttr('style');
        aSeoTextOpen.html('Показать меньше');
      }
    });
  }

  activateSticky() {
    let popular = document.querySelector('.block-popular');

    if  (popular) {
      popular.classList.add('sticky');
      popular.parentElement.classList.add('parent-sticky-container');

      new Sticky('.sticky', {
        marginTop: 20,
        marginBottom: 40,
        stickyFor: 1200,
        // stickyContainer: '.parent-sticky-container',
        stickyContainer: '.leftColumn',
      });
    }
  }

  changeAgent(e) {
    let $sel = $(e.target),
      val = $sel.val();

    let $input = $('[name="User.WorkPlace"]');
    $input.prop("disabled", val === 'True');
  }

  bodyClickWatch(e) {
    let elements = e.path || e.composedPath();

    let popupKey = 'popupToggle';
    let elem = elements.find(e => e.dataset && e.dataset[popupKey]);
    if (elem) {
      let dataValue = elem.dataset[popupKey];

      if (dataValue) {
        this.toggleAuth();
      }
    }
  }

  toggleAuth() {
    let login = document.querySelector('.popup--active');
    login.classList.remove('popup--active');

    setTimeout(() => {
      this.dispatchEvent(document, 'showRemindPopup');
    }, 200);
  }

  initMask() {
    let inputs = document.querySelectorAll('.phone-mask');
    inputs.forEach(item => {
      // noinspection JSCheckFunctionSignatures
      IMask(
        item, {
          mask: '+{38}(000)000-00-00',
        });
    });
  }

  initRating() {
    let ratingCont = document.querySelectorAll('.rating');

    ratingCont.forEach(item => {
      let rating = item.dataset.rateValue;

      rater({
        max: 5,
        readOnly: true,
        rating: parseInt(rating),
        element: item,
      });
    });
  }

  changeAgreement() {
    $(document).on('change', 'input[name=agreement]', (e) => {
      let input = $(e.target),
        submit = input.closest('form').find('[type=submit]');

      submit.prop('disabled', !e.target.checked);
    });
  }

  toggleInfo() {
    $('.open-author').on('click', function (e) {
      e.preventDefault();

      $('.author-connect').slideToggle();
    });

    $('.show-contacts').on('click', function (e) {
      e.preventDefault();
      $('.author-contacts').slideToggle();
    });
  }

  togglePass(e) {
    e.preventDefault();
    let elements = e.path || e.composedPath();
    let btn = elements.find(e => e.classList.contains('login-form__input-eye'));

    if (btn) {
      let password = btn.parentElement.querySelector('input');
      if (password) {
        let type = password.getAttribute('type');
        type = (type === 'text') ? 'password' : 'text';
        password.setAttribute('type', type);
      }
    }
  }

  dispatchEvent(el, type) {
    if ('createEvent' in document) {
      // modern browsers, IE9+
      let evt = document.createEvent('HTMLEvents');
      evt.initEvent(type, false, true);
      el.dispatchEvent(evt);
    } else {
      // IE 8
      // noinspection JSUnresolvedFunction
      let evt = document.createEventObject();
      evt.eventType = type;
      // noinspection JSUnresolvedFunction
      el.fireEvent('on' + evt.eventType, evt);
    }
  }

}
