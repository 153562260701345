export class changePrice {

  constructor() {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }
  }

  init() {
    this.switchers = document.querySelectorAll('[data-id=changeCurrency]');
    this.prices = document.querySelectorAll('.building-prices');

    if (this.switchers.length) {
      let active = this.switchers[0].querySelectorAll('span')[0];
      this.toggleCurrency(active.dataset.value);
    }
  }

  toggleCurrency(currency) {
    this.currentCurrency = currency;

    this.switchers.forEach(switcher => {
      switcher.querySelectorAll('span').forEach((item) => {
        if (item.dataset.value !== currency) {
          item.classList.remove('active');
        } else {
          item.classList.add('active');
        }

        item.addEventListener('click', e => this.clickCurrency(e));
      });
    });

    this.showPrice(currency);
  }

  clickCurrency(e) {
    let target = e.target;
    let currency = target.dataset.value;

    this.toggleCurrency(currency);
  }

  showPrice(currency) {
    this.prices.forEach(item => {
      if ( item.dataset.priceType !== currency ) {
        item.style.display = 'none';
      } else {
        item.style.display = 'block';
      }
    });
  }

}
