import Swiper from 'swiper';

export class singleBuilding {

  constructor() {
    this.swiperObject = false;

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }
  }

  init() {
    this.initComplexesListSlider();

    this.checkSwiper();

    window.addEventListener('resize', () => this.checkSwiper());
  }

  checkSwiper() {
    this.el = document.querySelector('.block-other');

    if (this.el) {
      this.swiper = this.el.querySelector('.complexes-other__slider');
      this.navigation = this.el.querySelector('.swiper-navigation');
      this.slides = this.swiper.querySelectorAll('.single-complex');

      this.slides.forEach(item => {
        item.classList.add('swiper-slide');
      });

      this.reinitComplexesListSlider();

      if (this.slides.length > 3) {
        this.initSwiper();
      } else {
        this.navigation.style.display = 'none';
        this.swiper.children[0].style.flexWrap = 'wrap';

        if (this.swiperObject) {
          this.swiperObject.destroy();
        }
      }
    }
  }

  initSwiper() {
    this.swiperObject = new Swiper(this.swiper, {
      slidesPerView: 1,
      autoHeight: true,
      navigation: {
        nextEl: '.complexes-other-next',
        prevEl: '.complexes-other-prev',
      },
      breakpoints: {
        1280: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          slidesPerColumn: 3,
          slidesPerColumnFill: 'row',
          autoHeight: false,
        }
      }
    });
  }

  reinitComplexesListSlider() {
    if (this.listSwiper) {
      this.listSwiper.destroy();
    }

    this.initComplexesListSlider();
  }

  initComplexesListSlider() {
    let selector = 'complexes-list__slider';
    let sliders = document.querySelectorAll('.' + selector);

    if (sliders) {
      this.listSwiper = sliders.forEach((item, index) => {
        let sel = selector + '-' + index;
        item.classList.add(sel);

        new Swiper(item, {
          slidesPerView: 1,
          pagination: {
            el: `.${sel} .complexes-list-pagination`,
            type: 'fraction',
            },
            navigation: {
                nextEl: '.complexes-list-next',
                prevEl: '.complexes-list-prev',
            },
        });
      });
    }
  }

}
