export class popupActions {
  constructor() {
    this.addToSession();
    this.checkPopup();
  }

  checkPopup() {
    let popup_old_design = sessionStorage.popup_old_design;
    if (popup_old_design !== '0') {
      this.showModal();
    } else {
      this.hideModal();
    }
  }

  addToSession() {
    let submit = this.constructor.documentEl('.old-design');
    if (submit) {
      submit.addEventListener('click', (e) => this.clickSubmit(e));
    }
  }

  clickSubmit(e) {
    if (e.target.className === 'btn-main') {
      this.hideModal();
      sessionStorage.setItem('popup_old_design', '0');
    }
  }

  hideModal() {
    let overlay = this.constructor.documentEl('.old-design_overlay');
    if (overlay) {
      overlay.style.display = 'none';
    }
  }

  showModal() {
    let overlay = this.constructor.documentEl('.old-design_overlay');
    if (overlay) {
      overlay.style.display = 'block';
    }
  }

  static documentEl(id) {
    return document.querySelector(id);
  }


}
